import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from 'react-tooltip';

import "../common/scss/common.scss";
import "./ImportInstitutionsApp.scss";

import {
    getInstitutionsSettings, setInstitutionsSettings, getInstitutionsData,
    selectEnableInstitutions, 
    setSearchTerm, setEnableInstitutions
} from "./store/importInstitutionsSlice";

import lang from '../common/lang/de.json'

import PageTitle from '../common/components/PageTitle'

import ImportInstitutionsDataTableHeader from "./ImportInstitutionsDataTableHeader";
import ImportInstitutionsDataTable from "./ImportInstitutionsDataTable";

import ActionButton from '../common/components/ActionButton'
import CheckBox from '../common/components/CheckBox'

const ImportInstitutionsApp = () => {
    const dispatch = useDispatch();

    const enableInstitutions = useSelector(selectEnableInstitutions);
    
    useEffect(() => {
        dispatch(getInstitutionsSettings());
        dispatch(getInstitutionsData());
    }, []);

    const onChangeEnableInstitutions = () => {
        const newEnableInstitutions = !enableInstitutions;
        dispatch(setEnableInstitutions(newEnableInstitutions));
    }

    // save settings, that calls the slice
    const saveSettings = () => {
        dispatch(setInstitutionsSettings({ enableInstitutions }));
    }

    const doSearch = (searchTerm) => {
        dispatch(setSearchTerm(searchTerm));
        dispatch(getInstitutionsData());
    }

    return (
        <div id="import_institutions_app_content" className="col-one-full">
            <PageTitle title={lang.settings.institutions.page_title} />

            <div className="flex flex__col col-one-half">
                <ActionButton onClick={saveSettings} text={lang.save} />

                <div className="dash-block-container-sp" style={{ marginTop: "3rem" }}>
                    <div className="dash-block-header text-uppercase" style={{position: "relative"}}>
                        <span>{lang.settings.institutions.page_title} </span>
                        <svg data-tip='' data-for="institutions_info" className="svg_icon" style={{top: 5, position: "relative"}}>
                            <use xlinkHref={"#icon-info"}></use>
                        </svg>

                        <ReactTooltip id="institutions_info" className="react-tooltip"  place="bottom" offset={{left: -55}} type="light" arrowColor="transparent">
                            <div>
                                Wenn Sie die Option &quot;Ausleiher Einrichtungen aktivieren&quot; wählen, werden folgende Funktionen freigeschaltet:
                                <ul>
                                    <li>
                                    Sie können Ausleiher Einrichtungen, z.B. Schulen oder Vereine, anlegen.<br />Diese Einrichtungen können die Ausleiher anschließend im Checkoutprozess auswählen.
                                    </li>
                                    <li>
                                    Die Einrichtungen werden auf den Reservierungs-PDF Dateien sowie in den Freigabeemails angezeigt
                                    </li>
                                    <li>
                                    Eine Auswertung über die Verleihhäufigkeit der einzelnen Einrichtungen wird freigeschaltet.
                                    </li>
                                </ul>
                            </div>
                        </ReactTooltip>
                    </div>

                    <div className="flex-column flex__wrap" style={{ padding: 10 }}>
                        <CheckBox htmlIdName="enableInstitutions" text={lang.settings.institutions.activate_institution} checked={enableInstitutions} onChange={onChangeEnableInstitutions} />
                    </div>
                </div>
            </div>

            <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                <ImportInstitutionsDataTableHeader onDoSearch={doSearch} />

                <div className="table_wrapper">
                    <ImportInstitutionsDataTable />
                </div>
            </div>
        </div>
    );
}

export default ImportInstitutionsApp;