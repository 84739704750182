import lang from "../common/lang/de.json";

export const importInstitutionsLang = lang.settings.institutions;

export const costCenterTableColumns = [
    {
        name: importInstitutionsLang.data_table.columns_names.name,
        selector: row => row.name,
        width: "400px",
        minWidth: "400px",
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.responsible_first_name,
        selector: row => row.responsible_first_name,
        width: "200px",
        minWidth: "200px",
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.responsible_last_name,
        selector: row => row.responsible_last_name,
        minWidth: "200px",
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.email,
        selector: row => row.email,
        minWidth: "200px",
        grow: 1,
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.address,
        selector: row => row.address,
        minWidth: "300px",
        grow: 1,
        sortable: false
    },
];
