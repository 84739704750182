import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Popup from '../common/components/Popup';

import lang from '../common/lang/de.json';

function PopupAddInstitution({ onAddInstitution, onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        responsibleFirstName: '',
        responsibleLastName: '',
        email: '',
        address: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        responsibleFirstName: false,
        responsibleLastName: false,
        email: false,
        address: false
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: false
            }));
        }
    };

    const handleSubmit = () => {
        let hasErrors = false;
        const newErrors = {};

        Object.keys(formData).forEach(key => {
            if (!formData[key]) {
                newErrors[key] = true;
                hasErrors = true;
            } else {
                newErrors[key] = false;
            }
        });

        setErrors(newErrors);

        if (!hasErrors) {
            onAddInstitution(formData);
            onClose();
        }
    };

    return (
        <Popup
            title={lang.add_institution_popup.title}
            showOkButton={true}
            className="add_institution_popup"
            onOkClicked={handleSubmit}
            onCancelClicked={onClose}
        >
            <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.name}<span className="required">*</span></span></label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        style={{ borderColor: errors.name ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.responsible_first_name}<span className="required">*</span></span></label>
                    <input
                        type="text"
                        name="responsibleFirstName"
                        value={formData.responsibleFirstName}
                        onChange={handleChange}
                        required
                        style={{ borderColor: errors.responsibleFirstName ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.responsible_last_name}<span className="required">*</span></span></label>
                    <input
                        type="text"
                        name="responsibleLastName"
                        value={formData.responsibleLastName}
                        onChange={handleChange}
                        required
                        style={{ borderColor: errors.responsibleLastName ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.email}<span className="required">*</span></span></label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        style={{ borderColor: errors.email ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.address}<span className="required">*</span></span></label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                        style={{ borderColor: errors.address ? 'red' : '', flex: 1 }}
                    />
                </div>
            </form>
        </Popup>
    );
}

PopupAddInstitution.propTypes = {
    onAddInstitution: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default PopupAddInstitution;
