import React from 'react';
import PropTypes from 'prop-types';

import lang from "../lang/de.json";

import DataTable from 'react-data-table-component';
import "../scss/DataTable.scss";

import NoDataFound from "./NoDataFound";

const QVDataTable = (props) => {
    const paginationLang = lang.data_table.pagination;
    const paginationRowsPerPageOptions = [10, 20, 30, 40, 50];

    // this is bool, so check for undefined, to check if it is set
    const pagination = typeof (props.pagination) !== "undefined" ? props.pagination : true;

    const paginationComponentOptions = {
        rowsPerPageText: paginationLang.rowsPerPageText,
        rangeSeparatorText: paginationLang.rangeSeparatorText,
        selectAllRowsItem: false,
        selectAllRowsItemText: paginationLang.selectAllRowsItemText,
        noRowsPerPage: false
    };

    const tableCustomStyles = {
        rows: {
            style: {
                backgroundColor: "#fbfbfb",
                '&:nth-child(2n)': {
                    backgroundColor: '#ffffff',
                },
            }
        }
    }

    return (
        <DataTable
            customStyles={tableCustomStyles}
            title={props.title}
            columns={props.columns}
            persistTableHead={true}
            data={props.data}
            progressPending={props.isLoading}
            pagination={pagination}
            paginationServer
            paginationTotalRows={props.paginationTotalRows}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            onChangePage={props.onChangePage}
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={props.paginationPerPage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            noDataComponent={<NoDataFound text={props.noDataToShowText} />}
            defaultSortAsc={props.defaultSortAsc}
            onSort={props.onSort}
            sortServer
            defaultSortFieldId={props.defaultSortFieldId ? props.defaultSortFieldId : 1}
            progressComponent={<div style={{ fontSize: '14px', fontWeight: "bold", padding: '24px' }}>{lang.loading}</div>}
        />
    );
}

QVDataTable.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.bool,
    paginationTotalRows: PropTypes.number,
    onChangeRowsPerPage: PropTypes.func,
    onChangePage: PropTypes.func,
    noDataToShowText: PropTypes.string,
    paginationPerPage: PropTypes.number,
    defaultSortAsc: PropTypes.bool,
    onSort: PropTypes.func,
    defaultSortFieldId: PropTypes.number
};

export default QVDataTable;